<script setup lang="ts">
import {computed, ref} from "vue";

type Quote = {
  invoiceId: string,
  url: string,
}

const quote = ref<Quote | null>(null)

const quoteUrl = computed(() => `${quote.value?.url}#navpanes=0`)

function onGenerated(q: Quote) {
  quote.value = q;
}

</script>

<template>
  <main class="get-quote-page">
    <section class="form">
      <TeacherQuoteForm @generated="onGenerated"/>
    </section>

    <section class="quote">
      <iframe v-if="quote?.url" :src="quoteUrl" width="100%" height="100%" allowfullscreen></iframe>
      <div v-else class="iframe-placeholder">Your quote will appear here.</div>
    </section>

  </main>
</template>

<style scoped lang="scss">
.get-quote-page {
  display: flex;
  flex-wrap: wrap;

  section {
    align-content: center;
    padding: 1rem;
  }

  section.form {
    flex: 1 1 25rem;
  }

  section.quote {
    flex: 1 1 35rem;
    min-height: 45rem;
  }

  .iframe-placeholder {
    font-size: 1.5rem;
    color: #aaa;
    text-align: center;
  }
}
</style>
