import {createInertiaApp} from "@inertiajs/vue2"
import Vue from "vue"

export async function initInertia(args = {}) {
  const {layout} = args
  try {
    await createInertiaApp({
      id: 'inertia-app',

      resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        const page = pages[`./Pages/${name}.vue`]
        if (layout && !page.default?.layout) {
          page.default ??= {}
          page.default.layout = layout
        }
        return page
      },

      setup({el, App, props, plugin}) {
        Vue.use(plugin)
        new Vue({render: h => h(App, props)}).$mount(el)
      },
    })
  } catch (e) {
    // console.error({name: e.name, message: e.message, stack: e.stack})
    if (e.name === 'TypeError' && e.message.includes('dataset)')) {
      // Ignore it: we get this when loading any non-inertia view, because the #inertia-app div isn't present.
      // We could add the empty div to the base blade layouts, but I'd rather the unused inertia app just quietly quit.
    }
  }
}
