<script>
	export default {
		name: "AdminMain",

		components: {},

		props: {},

		data() {
			return {}
		},

		computed: {},

		methods: {},

	}
</script>

<template>
  <span>
		<slot></slot>
  </span>
</template>

<style lang="scss" scoped>
</style>
