<script>

  import axios from 'axios';
  import Resource from "../../services/resource";

  export default {
    name: "WithServices",

    provide() {
      return {
        service: this.service
      }
    },

    props: {
      discoveryUrl: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        serviceDescriptions: null,
        services: {},
        discovery: this.discover(),
      }
    },

    methods: {
      async discover() {
        let response = await axios.get(this.discoveryUrl)
        this.serviceDescriptions = response.data.data
        this.serviceDescriptions.forEach(
          service =>
            this.services[service.name] = new Resource(service.name, service.endpoints)
        )
				return this.services
      },

      async service(name) {
        let services = await this.discovery
        return services[name]
      }

    },

  }
</script>

<template>
	<span>
		<slot></slot>
	</span>
</template>
