<script lang="ts" setup="">
import {computed, watch} from "vue";
import * as Sentry from "@sentry/browser";

type SentryContext = Record<string, any>

const props = defineProps<{ context: SentryContext }>()
const context = computed(() => props.context)

function onContext() {
  // console.debug("Init Sentry Context", context.value)
  Sentry.setUser(context.value);
}

watch(context, onContext, {immediate: true})


</script>

<template>
  <div class="debug debug-border-green">
    <pre>SENTRY CONTEXT: {{ context }}</pre>
  </div>
</template>
