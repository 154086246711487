<style lang="scss" scoped>
	.statistics {
		tr {
			th {
				text-align: center;
			}

			td {
				text-align: center;
			}
			td:first-of-type {
				font-weight: bold;
				text-align: right;
			}
		}
	}
</style>

<template>
	<b-card title="User Statistics" class="mt-4">
		<b v-if="loading">Loading...</b>
		<div v-else>
			<table class="table statistics">
				<tr>
					<th v-for="(header, index) in statistics.headers" :key="index" v-html="header"></th>
				</tr>
				<tr v-for="(row, index) in statistics.rows" :key="index">
					<td v-for="(value, index) in row" :key="index" v-html="value"></td>
				</tr>
			</table>
		</div>
	</b-card>
</template>

<script>
export default {
	name: "AdminDashboard",

	components: {},

	props: {
		endpoint: {
			type: String,
			required: true
		}
	},

	mounted() {
		this.load();
	},
	data() {
		return {
			loading: true,
			statistics:  {}
		}
	},

	methods: {
		load() {
			this.loading = true;
			axios.post(this.endpoint)
			.then((response) => response.data)
			.then((data) => {
				this.statistics = data;
			}).catch((error) => {
				this.errorMessage = error;
			}).finally(() => {
				this.loading = false
			})
		}
	},

	computed: {},

}
</script>


