import * as Sentry from "@sentry/vue";
import Vue from "vue";
import {browserTracingIntegration} from "@sentry/vue";


export function initSentry(args = {}) {
  const {environment} = {
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? getSentryEnvironment(),
    ...args
  }

  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      Vue,
      environment,
      release: import.meta.env.VITE_SENTRY_RELEASE,
      logErrors: true, // only if local or development ideally.
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        browserTracingIntegration({
          tracingOrigins: ["lyrics2learn.local", "lyrics2learn.com", "www.lyrics2learn.com", "dev.lyrics2learn.com", /^\//],
        }),
      ],
      tracesSampleRate: 0.1, // this may need to be dropped down
    })
    console.info("Sentry initialized", {environment})
  } else {
    console.warn("VITE_SENTRY_DSN not defined -- Sentry integration disabled")
  }
}

export function getSentryEnvironment() {
  switch (window.location.hostname) {
    case "www.lyrics2learn.com":
    case "lyrics2learn.com":
      return "production"
    case "staging.lyrics2learn.com":
      return "staging"
    case "dev.lyrics2learn.com":
      return "development"
    default:
      return "local"
  }
}
