
import axios from 'axios';

class Resource {

	constructor(name, endpoints) {
		this.name = name
		this.endpoints = endpoints
        this.debug = false;
	}

	async browse(query = {}) {
        this.debug && console.log("BROWSE", query)
		return axios.get(this.getEndpoint('browse'), {params: query})
	}

	async read(id) {
        this.debug && console.log("READ", id)
		return axios.get(this.getEndpoint('read', id))
	}

	async edit(id, entity) {
        this.debug && console.log("EDIT " + id, entity)
		return axios.patch(this.getEndpoint('edit', id), entity)
	}

	async add(entity) {
        this.debug && console.log("ADD", entity)
		return axios.post(this.getEndpoint('add'), entity)
	}

	async delete(id) {
        this.debug && console.log("DELETE", id)
		return axios.delete(this.getEndpoint('delete', id))
	}

	getEndpoint(name, id) {
	    const endpoint = this.endpoints[name];
	    if (!endpoint) {

	        throw `No endpoint '${name}' defined for service '${this.name}'`
        }
	    if (id !== undefined) {
	        return substituteIdPlaceholder(endpoint, id);
        } else {
	        return endpoint;
        }
    }

}

function substituteIdPlaceholder(url, id) {
	return url.replace(':id', id)
}

export default Resource
