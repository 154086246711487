<template>
	<div>
		<b-navbar class="navbar" type="dark" variant="primary" toggleable="md">
		    <b-navbar-brand href="/admin/dashboard">Lyrics2Learn Admin</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse" toggle-class="hamburger"></b-navbar-toggle>

			<b-collapse id="nav-collapse" class="hamburger" is-nav>
				<b-navbar-nav>
                    <b-nav-item-dropdown text="Users" right>
                        <b-dropdown-item href="/admin/users/list">Manage Users</b-dropdown-item>
                        <b-dropdown-item href="/admin/school_import">Import Schools</b-dropdown-item>
                        <b-dropdown-item href="/admin/import_classrooms">Import Teacher Classrooms</b-dropdown-item>
                        <b-dropdown-item href="/admin/move_students">Move Students</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown text="Schools &amp; Promotions" right>
                        <b-dropdown-item href="/admin/school/list">Manage Schools</b-dropdown-item>
                        <b-dropdown-item href="/admin/districts/list">Manage Districts</b-dropdown-item>
                        <b-dropdown-item href="/admin/promotions/list">Manage Promotion Codes</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown text="Subscribers" right>
                        <b-dropdown-item href="/admin/subscribers/all">All Subscribers</b-dropdown-item>
                        <b-dropdown-item href="/admin/subscribers/free_trial">Trial Subscribers</b-dropdown-item>
                        <b-dropdown-item href="/admin/subscribers/stripe">Stripe Subscribers</b-dropdown-item>
                        <b-dropdown-item href="/admin/subscribers/ios">Apple Mobile Subscribers</b-dropdown-item>
                        <b-dropdown-item href="/admin/subscribers/android">Android Mobile Subscribers</b-dropdown-item>
                        <b-dropdown-item href="/admin/subscribers/school_pay">Seat License Subscribers</b-dropdown-item>
                        <b-dropdown-item href="/admin/subscribers/bad_subscriptions">Subscription Anomalies</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown text="Reports" right>
                        <b-dropdown-item href="/admin/usage">Usage</b-dropdown-item>
                        <b-dropdown-item href="/admin/usage_reading_level">Usage By Reading Level</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown text="Other" right>
                        <b-dropdown-item href="/admin/mail_templates/list">Templates</b-dropdown-item>
                        <b-dropdown-item href="/admin/events">Event Logs</b-dropdown-item>
                    </b-nav-item-dropdown>

				</b-navbar-nav>

				<b-navbar-nav class="ml-auto">
					<b-nav-item class="nav-widget" align="right" justified @click="logout()">
						Logout
					</b-nav-item>
				</b-navbar-nav>
			</b-collapse>


		</b-navbar>

	</div>
</template>

<script>
	export default {
		name: "AdminNav",

		components: {},

		props: {},

		data() {
			return {}
		},

		computed: {},

		methods: {
			logout() {
				if (window.confirm("Logout Now?")) {
					axios.post('/logout').then(() => window.location = '/')
				}
			}
		},

	}
</script>
